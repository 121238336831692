.react-flow {
  background-color: #f8f8f8;
  width: 100%;
  height: 100%;
}

.custom-node {
  padding: 10px 15px;
  border-radius: 8px;
  min-width: 180px;
  position: relative;
} 
.file-node {
  background-color: #F5F5F5;
  border: 2px solid #E3E3E3;
  box-shadow: 0px 3px 5px 0px #0000001F;
}

.operation-node {
  background-color: #E8E9FF; 
}

.node-content {
  display: flex;
  align-items: center;
  gap: 8px;
}

.node-icon {
  width: auto;
  height: 17px;
}

.node-label {
  font-size: 16px;
  color: #000;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
  flex: 1;
}

.connected-status {
  position: absolute;
  top: -23px;
  left: 0;
  font-size: 13px;
  color: #000000;
  padding: 0;
  display: flex;
  gap: 3px;
  align-items: center;
}

.connected-status span {
  width: 12px;
  height: 12px;
  border-radius: 3px;
  display: inline-block;
  background: #4AA17F;
}
.react-flow__handle {
  width: 10px;
  height: 10px;
  background-color: #EDEDED;
  border: 1px solid #B3B3B3;
  border-radius: 2px;
}

.react-flow__edge-path, .react-flow__connection-path
{
  stroke: #B3B3B3;
}
.react-flow__handle-left {
  left: -4px;
}

.react-flow__handle-right {
  right: -4px;
}

.work-book-box {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.component-data {
  flex: 1;
  min-height: 0;
  position: relative;
}

.flow-actions {
  position: absolute;
  top: 46px;
  right: 12px;
  z-index: 4;
  border: 1px solid #CCCCCC;
  border-radius: 14px;
  overflow: hidden;
  background-color: #F5F5F5;
  padding: 6px;
  box-shadow: 0px 4px 15px 0px #0000001F;
}
.combined-button {
  padding: 0 !important;
  height: 36px !important;
  border: none !important;
  border-radius: 4px !important;
  overflow: hidden !important;
  display: flex !important;
  box-shadow: none !important;
  background-color: transparent;
  align-items: center !important; 
  gap:3px;
  font-family: 'Work Sans', sans-serif;

}

.combined-button .execute-section {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0 12px;
  height: 100%;
  background: #F5F5F5;
  color: #344054;
  font-size: 13px;
  font-weight: 500;
}
.combined-button .save-section {
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 100%;
  background: #32377F;
  color: white;
  border-radius: 9px;
  font-size: 13px;
  font-weight: 500;
}
.combined-button:hover .execute-section {
  background: #f5f5f5;
}

.combined-button:hover .save-section {
  background: #272a66;
}

.combined-button .anticon {
  font-size: 16px;
}
.showbuilder-active
{
  height: calc(100% - 30px);
}
/* .react-flow__panel.bottom {
  bottom: 30px;
} */

.needs-save .connected-status span {
  background: #FF4D4F;
}

.query-status {
  position: absolute;
  top: -20px;
  right: 0;
  background: #4CAF50;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 12px;
}

.back-btn {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #cccccc;
  background: #fff;
  padding: 0;
  /* position: absolute; */
  margin: 5px;
}

.save_golden_batch{
  display: flex;
  gap: 20px;
  margin-bottom: 15px;
}

button:disabled {
  opacity: 0.5; /* Reduce opacity to indicate it's disabled */
  cursor: not-allowed; /* Change the cursor to indicate it's disabled */
}

.custom-node .node-content {
  position: relative;
  display: flex;
  align-items: center;
}

.custom-node .node-actions {
  display: flex;
  align-items: center;
  gap: 4px;
}

.custom-node .delete-icon {
  opacity: 0.7;
  transition: opacity 0.2s;
}

.custom-node .delete-icon:hover {
  opacity: 1;
}

.highlighted-row {
  position: relative;
  background-color: #fdeb90 !important; /* Highlight color */
}


.highlighted-row  td {
  /* background: none; */
  background-color: #fdeb90 !important;
  }
  .rundeviation-table .ant-table-column-title{
    text-align: center;
    overflow: hidden;
    /* width: fit-content; */

  }
  
  .ant-table-cell:has(.run-deviation) {
    margin-left: 2PX;
    background-color: #ef3a3ad9 !important;
  }

  .ant-skeleton.ant-skeleton-element{
    width: 100%;
    height: 25px;
  }
  .ant-skeleton.ant-skeleton-element .ant-skeleton-input-lg{
    width: 100%;
    height: 25px;
  }
  .ant-form-item-required{
    font-size: 16px !important;
    font-weight: 500 !important;
  }
  .ant-select.ant-select-single{
    height: 38px !important;
  }


 .golden-values .ant-table-selection-column{
  background-color: #9999995c !important;
 }