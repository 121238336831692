@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
body 
{
  font-family: 'Work Sans', sans-serif;
}
.worksans
{
  font-family: 'Work Sans', sans-serif;
}
/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.arrow-btn {
  transition: filter 0.3s ease;
}
.arrow-btn:hover img {
  filter: brightness(0);
}
.arrow-btn {
  padding: 0 5px;
}
.divider {
  height: 34px;
  width: 1px;
  background-color: #ccc;
  margin: 0;
}
.menu-dropdown {
  background-color: #323780;
  padding: 11px 9px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  color: #fff !important;
}
.common-btn {
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: auto;
  padding: 0 8px;
}
.common-btn .ant-typography,
.label-dropdown .ant-typography {
  margin: 0;
  font-size: 11px;
  font-weight: 500;
  color: #999999;
  font-family: 'Work Sans', sans-serif;
}
.common-btn:hover {
  background-color: transparent !important;
}
.common-btn:hover .ant-typography,
.label-dropdown:hover .ant-typography {
  color: #383838;
}
.common-btn:hover img,
.label-dropdown:hover img {
  filter: brightness(0);
}
.feedback-link {
  color: rgb(37, 41, 99);
  display: flex;
  gap: 7px;
  font-family: 'Work Sans', sans-serif;
  font-weight: 500;
  font-size: 16px;
}
.label-dropdown {
  padding: 0 8px;
  display: inline-block;
}
.label-dropdown > div {
  flex-direction: column;
  display: flex;
}
.label-box {
  display: flex;
  gap: 6px;
}
.insight-header {
  background-color: rgb(245, 245, 245);
  border-bottom: 1px solid rgb(204, 204, 204);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: auto;
  align-items: center;
  padding: 10px 20px;
}
.insight-header .ant-space {
  flex-wrap: wrap;
}
.side-tray {
  padding: 10px;
  background: #f5f5f5;
  max-width: 122px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  border-right: 1px solid #CCCCCC;
}
.panel-content:has(.main-content) {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 98px);
  padding: 0;
}
.tray-header-btn button {
  background-color: transparent !important;
  padding: 0;
}
.textarea-box {
  display: flex;
  /* border: 1.5px solid #d9d9d9; */
  border-radius: 4px;
  min-height: 70px;
  min-width: 90px;
  /* background: #fff; */
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 35px;
  cursor: pointer;
  font-weight: 600;
  color: #252963;
}
.textarea-box:hover 
{
  background-color: #eee;
}

.Active.textarea-box {
  background: #252963;
  color: #fff;
  border-radius: 4px 4px 0 0;
}
.arrowblue {
  background-color: transparent !important;
  padding: 0;
  height: auto !important;
}

.insight-tabs {
  width: 100%;
  max-width: 350px;
  min-width: 350px;
  height: 100%;
  border-right: 1px solid #ccc;
  display: flex;
  transition: all 0.3s ease-in-out 0s;
  flex-direction: column;
}
.tabs-header button {
  flex: 1;
  background: transparent;
  border: none;
  border-bottom: 1px solid #cccccc;
  padding: 13px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  font-size: 15px;
  color: #252963;
  font-weight: 400;
}
.tabs-header button:first-child {
  border-right: 1px solid #ccc;
}
.tab-content {
  position: relative;
  height: calc(100vh - 169px);
}
.Search-bar {
  display: flex;
  gap: 10px;
  flex-direction: column;
}
.Search-bar input[type='text'],
.Search-bar input[type='search'] {
  border: 1px solid #cccccc;
  height: 38px;
  border-radius: 7px;
  font-family: 'Work Sans', sans-serif;
  padding: 0 10px;
  font-size: 15px;
  background: white;
}
.Search-bar input:focus {
  outline: 0;
}
.Search-bar label {
  display: flex;
  color: #666666;
  gap: 5px;
  font-size: 13px;
  font-family: 'Work Sans', sans-serif;
}
.btn-secondary-new {
  height: 32px;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  background-color: transparent;
  color: #344054;
  font-size: 13px;
  font-family: 'Work Sans', sans-serif;
  padding: 0 12px;
  gap: 4px;
  display: inline-flex;
  line-height: 18px;
}
.btn-secondary-new:hover {
  background-color: #eee;
}
.btn-primary-new {
  height: 32px;
  border: 1px solid #252963;
  border-radius: 8px;
  background-color: #252963;
  color: #fff;
  font-size: 13px;
  font-family: 'Work Sans', sans-serif;
  padding: 0 12px;
  gap: 4px;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  line-height: 18px;
}
.filter-btns {
  margin-bottom: 30px;
  margin-top: 20px;
}
.filterBtn {
  color: #252963;
  font-size: 13px;
  margin-right: 10px;
}
.assets-groups h4 {
  margin: 0;
  color: #32377f;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Work Sans', sans-serif;
}
.assets-groups .btn.btn-secondary-new {
  height: 24px;
}
.assets-groups .ant-flex {
  padding-bottom: 7px;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 9px;
}
.assets-groups .ant-typography {
  font-size: 12px;
  color: #666666;
  font-family: 'Work Sans', sans-serif;
}
.action-btn button {
  border: none;
  height: auto;
  padding: 0;
  background-color: transparent;
}

.action-btn {
  gap: 6px;
}
.assets-tree {
  margin-top: 26px;
}
.assets-tree h4 {
  margin: 0 0 7px;
  color: #32377f;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Work Sans', sans-serif;
}
.assets-tree ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
  height: calc(100vh - 565px);
  overflow: auto;
}
.assets-tree span {
  color: #252963;
  font-size: 12px;
  font-weight: 600;
  font-family: 'Work Sans', sans-serif;
  width: 24px;
  display: inline-block;
  text-align: center;
}
.assets-tree .ant-typography {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  font-family: 'Work Sans', sans-serif;
  margin: 0 0 10px;
  line-height: 14px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.assets-tree li {
  padding: 8px;
  border-bottom: 1px solid #e6e6e6;
}
.common-box {
  flex: 1;
  height: 100%;
  padding: 15px 20px 40px;
  background: #f5f5f5;
  width: calc(100% - 360px);
  overflow: auto;
}
.form-input {
  position: relative;
  margin-bottom: 8px;
}
.form-input input {
  border: 1px solid #cccccc;
  width: 100%;
  background: #fff;
  height: 46px;
  padding-left: 150px;
  outline: 0 !important;
}

.columns-btn {
  display: flex;
  align-items: center;
  gap: 14px;
  background: #f5f5f5;
  border: 1px solid #cccccc;
  max-width: 144px;
  padding: 0 12px;
  height: 100%;
  position: absolute;
  left: 0;
  width: 100%;
  font-family: 'Work Sans', sans-serif;
  font-weight: 500;
  color: #4d4d4d;
  font-size: 16px;
}
.work-book-box {
  padding: 0;
  background: #fff;
  border: 1px solid #cccccc;
  margin-top: 0px;
  display: flex;
  flex: 1;
  flex-direction: column;
}
.work-book-box h4 {
  font-size: 20px;
  color: #000;
  font-family: 'Work Sans', sans-serif;
  font-weight: 400;
}
.dropfile-here1 {
  border-right: 1px solid #b3b3b3;
  border-bottom: 1px solid #b3b3b3;
  text-align: center;
  font-size: 20px;
  color: #999999;
  font-family: 'Work Sans', sans-serif;
  font-weight: 400;
  padding: 5px 0 20px;
}
.dropfile-here2 {
  display: flex;
  width: 76px;
  border-right: 1px solid #b3b3b3;
  height: calc(100% + 45px);
  top: -45px;
  position: relative;
  align-items: center;
  font-size: 20px;
  color: #999999;
  font-family: 'Work Sans', sans-serif;
  font-weight: 400;
  padding-right: 16px;
}
.dropfile-here3 {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #999999;
  font-family: 'Work Sans', sans-serif;
  font-weight: 400;
}
.dropfile {
  position: relative;
  height: 100%;
}
.dropfile-here3 {
  border-right: 1px solid #b3b3b3;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #999999;
  font-family: 'Work Sans', sans-serif;
  font-weight: 400;
  flex: 1;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  border-bottom: 1px solid #b3b3b3;
}
.arrow-btn1 {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #cccccc;
  background: #fff;
  padding: 0;
  position: absolute;
  right: -12px;
  top: 8px;
  z-index: 1;
}
.arrow-btn1 img {
  margin-left: -1px;
  transition: all 0.3s ease-in-out 0s;
}
.sidebar-remove .insight-tabs {
  max-width: 0;
  min-width: 0;
  border: none;
}
.sidebar-remove .insight-tabs .tabs-header,
.sidebar-remove .insight-tabs .tab-content * {
  display: none !important;
}
.sidebar-remove .insight-tabs .tab-content {
  padding: 0;
}
.sidebar-remove .insight-tabs .tab-content .arrow-btn1 {
  display: flex !important;
  top: 50px;
  right: -14px;
}
.sidebar-remove .insight-tabs .tab-content .arrow-btn1.active img {
  display: block !important;
  margin: 0;
  transform: rotate(-180deg);
}
.react-grid-layout:has(.main-content) , .react-grid-layout:has(.main-content) .react-grid-item , .react-grid-layout:has(.main-content) .react-grid-item > div
{
  height: auto !important;
}
.overlay-text {
  background-color: #F2F6F9;
  border-top: 1px solid #EEEEEE;
  padding: 5px 10px;
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  font-size: 12px;
  color: #252963;
  right: 0;
  font-family: 'Work Sans', sans-serif;

}
.css-1xodasp:has(.main-content) {
  padding-bottom: 0 !important;
}
.relative
{
  position: relative;
}


.dataContent h4 
{
  color: #252963;
  font-size: 24px;
  font-family: 'Work Sans', sans-serif;
  font-weight: 500;
  margin-bottom: 10px;
  letter-spacing: 0;
}
.main-content
{
  letter-spacing: 0;
  height: calc(100vh - 119px);
}

.connect-data {
  font-weight: 500;
  display: flex;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  color: #000000;
  font-size: 16px;
  font-family: 'Work Sans', sans-serif;
}
.connect-data:hover ,.connect-data.active
{
  background: #E9E9F5;
}
.data-outer ul {
  margin: 0;
  padding-left: 36px;
  list-style-type: none;
  padding-top: 10px;
  overflow: hidden;
}
.data-outer ul ul{
  padding-left: 4px;
}
.data-outer ul li 
{
  font-size: 14px;
  color: #666666;
  font-family: 'Work Sans', sans-serif;
  padding-bottom: 5px;
  position: relative;
  padding-left: 10px;
}
.data-outer ul ul li 
{
  padding-left: 10px;
}
.data-outer ul li::before {
  left: 0;
  height: 1px;
  width: 10px;
  background-color: #ccc;
  content: "";
  position: absolute;
  top: 10px;
}
.data-outer ul li::after {
  left: 0;
  height: 100%;
  width: 1px;
  background-color: #ccc;
  content: "";
  position: absolute;
  top: -16px;
}
.data-outer ul li span {
  width: 100%;
  word-break: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.connect-data img {
  position: relative;
  top: -1px;
}
.seprater {
  display: inline-block;
  width: 100%;
  height: 1px;
  background: #D9D9D9;
  margin: 22px 0;
}

.data-source ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
  max-height: calc(100vh - 565px);
  overflow: auto;
  display: contents;
}
.data-source span {
  color: #252963;
  font-size: 12px;
  font-weight: 600;
  font-family: 'Work Sans', sans-serif;
  width: 24px;
  display: inline-block;
  text-align: center;
}
.data-source .ant-typography {
  color: #666666;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Work Sans', sans-serif;
  margin: 0;
  line-height: 14px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-align: left;
}
.data-source li {
  padding: 8px; 
}
.data-source span img 
{
  position: relative;
  margin: auto;
  top: -1px;
}
.data-source li:hover
{
  background-color: #eeeeee75;
}
.data-outer
{
  margin-bottom: 5px;
}
.upload-btn {
  margin-top: 10px;
  display: inline-block;
  width: 100%;
}
.upload-btn button {
  border: 1px dashed #323780;
  height: 200px;
  color: #323780;
  width: 100%;
  background: rgba(50, 55, 128, 0.0509803922);
  font-weight: bold;
  font-size: 20px;
  flex-direction: column;
}
.upload-btn .ant-upload
{
  width: 100%;
}
.upload-btn  .ant-btn-icon
{
  font-size: 30px;
}
.insight-common-ul ul
{
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.insight-common-ul ul li
{
  cursor: pointer;
}
.insight-common-ul ul li .ant-typography
{
  font-size: 15px;
  color: #000000;
  font-family: 'Work Sans', sans-serif;
  margin: 0;
}
.insight-common-ul ul li img
{
  position: relative;
  top: -1px; 
}
.heading-bold
{
  margin-bottom: 15px !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  font-family: 'Work Sans', sans-serif;
  letter-spacing: 0;
}
.insight-common-ul ul li:hover .ant-typography
{
  color: #252963;
}
.ant-btn-primary
{
  background-color: #323780 !important;
  border-color: #323780 !important;
}
.ant-btn-default:hover 
{
  border-color: #323780 !important;
  color: #323780 !important;
}
.App
{
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.inner-box {
  display: flex;
  width: 100%;
}

.data-table {
  width: 100%;
  border-right: 1px solid #ccc;
}
.chart-data {
  width: 35%;
}
.sidebar-outer
{
  width: calc(100% - 122px);
}
.component-data {
  min-height: 300px;
  max-height: 300px;
  width: 100%;
  display: inline-block;
  background-color: #FFF;
}
.data-table .ant-table-content
{
  overflow: auto !important;
  height: calc(100vh - 208px);
}
.data-source li .ant-space-item {
  align-items: center;
  justify-content: center;
  display: inline-flex;
  text-align: center;
}

.flow-canvas {
  display: flex;
  height: 80vh;
  width: 79vw;
  overflow: hidden;
}

.component-data .ant-table {
  width: 100%;
}

.component-data .ant-table-thead > tr > th {
  background: #f5f5f5;
  font-weight: 500;
  color: #252963;
  font-family: 'Work Sans', sans-serif;
}

.component-data .ant-table-tbody > tr > td {
  font-family: 'Work Sans', sans-serif;
}

.component-data .ant-table-tbody > tr:hover > td {
  background: #f0f0f0;
}

.component-data {
  padding: 0;
  overflow: auto;
}

/* Add these new styles */
.files-section {
  padding: 20px;
}

.files-heading {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
  color: #000;
  font-family: 'Work Sans', sans-serif;
}

.files-table-container {
  background: #f5f5f5;
}

.connected-files-section {
  padding: 8px;
  color: #666;
  font-size: 14px;
  font-family: 'Work Sans', sans-serif;
  border-bottom: 1px solid #e6e6e6;
}


/* Adjust the component-data class */
.component-data {
  min-height: auto;
  max-height: none;
}

.files-table .ant-table-tbody > tr > td span {
  cursor: pointer;
}

.files-table-container table
{
  border-radius: 0;
  margin-bottom: 15px;
}
.files-table-container table thead th 
{
  background-color: #EDEDED !important;
  border-radius: 0 !important;
  font-size: 16px !important;
  color: #4D4D4D !important;
  font-weight: 500 !important;
  font-family: 'Work Sans', sans-serif; 
  padding: 11px 16px !important;
}
.files-table-container table tbody tr td 
{
  padding: 11px 16px !important;
  color: #333333;
  font-size: 16px;
  font-family: 'Work Sans', sans-serif; 
  border-color: #ccc !important; 
  background: transparent !important;
}
.files-table-container .first-table table tbody tr:first-child td 
{
  border: none !important;
  height: 48px;
}
.files-table-container .first-table table tbody tr:first-child td span
{
  display: none;
}
.files-table-container table tbody tr
{
  background-color: transparent !important;
}
.files-table-container .ant-table
{
  background-color: transparent !important;
}
.files-table-container table tbody tr:last-child td 
{
  border: none !important;
}
.second-table thead
{
  display: none;
}
.operation-form .ant-form-item
{
  margin-bottom: 15px;
}


.column-checkbox-group  .checkbox-item {
  margin-bottom: 10px;
  width: 100%;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
}
.column-checkbox-group  .checkbox-item:last-child 
{
  border: none;
}
:where(.css-dev-only-do-not-override-apn68).ant-checkbox-checked .ant-checkbox-inner
{
  background-color: #252963;
  border-color: #252963;
}
:where(.css-dev-only-do-not-override-apn68).ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner, :where(.css-dev-only-do-not-override-apn68).ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  border-color: #323780;
}
:where(.css-dev-only-do-not-override-apn68).ant-checkbox .ant-checkbox-inner:after
{
  top: 47%;
}
:where(.css-dev-only-do-not-override-apn68).ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
  background-color: #252963;
  border-color: transparent;
}
.tab-inner {
  height: 100%;
  padding: 20px 20px 30px;
  overflow: auto;
}
table.table-custom {
  display: table;
  border-collapse: separate;
  box-sizing: border-box;
  text-indent: initial;
  border-spacing: 2px;
  border-color: gray;
}
table.table-custom {
  border-collapse: collapse;
}
t.table-custom head {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
}
.table-custom tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}
.table-custom td, .table-custom th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  white-space: nowrap;
}
.table-custom thead th {
  vertical-align: bottom; 
  background: #f6f6f6;
}
.stepsheight
{
  height: calc(100dvh - 54px);
}
.main-box
{
  height: calc(100dvh - 77px);
  overflow: auto;
}
.custom-grid
{
  grid-template-columns: repeat(auto-fit, 250px);  
}
.quality-measure
{
  margin-left: auto;
  width: calc(100% - 44px);
}
.parameter-section
{
  height: 100%;
  overflow: auto;
  width: calc(100% - 300px);
}
.select-target .ant-select-selector {
  background: #F7F7F7 !important;
  border-color: #B3B3B3 !important;
  border-radius: 4px !important;
}
.select-target .ant-select-selection-placeholder
{
  color:  #000;
}
.querybuilder  .ruleGroup {
  background: transparent;
  border: none;
  padding: 0;
  gap: 20px;
}
.querybuilder .ruleGroup-body select , .querybuilder .ruleGroup-body input {
  height: 32px;
  border: 1px solid #B3B3B3;
  border-radius: 4px;
  background: #F7F7F7;
}
/* .stages
{
  height: calc(100dvh - 150px);
} */
.common-table th.ant-table-cell {
  background: #EDEEF2 !important;
  border: 1px solid #F7F7F7;
  border-top: 1px solid #252963;
  border-radius: 0 !important;
  font-weight: 500 !important;
  color: #000 !important;
}
.common-table tbody tr:nth-child(odd) {
  background: #F7F7F7;
}
.common-table tbody tr td {
  padding: 5px 16px !important;
  border-right: 2px solid #F7F7F7;
}
.main-header .ant-select-selector {
  background: #F7F7F7 !important;
  border: 1px solid #B3B3B3 !important;
  max-width: 200px !important;
  font-size: 14px;
  font-family: 'Work Sans', sans-serif;
  width: 100% !important;
}
 

.main-header .ant-select  {
  width: 100%;
  max-width: 200px;
}
.main-header .ant-picker {
  background: #F7F7F7;
  border-color: #B3B3B3;
  max-width: 230px;
}
.main-header .ant-picker.ant-picker-disabled {
  cursor: not-allowed;
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.25);
  background: rgba(0, 0, 0, 0.04);
  opacity: 0.5;
  box-shadow: none;
}
@media screen and (max-width:1452px)
{
  .insight-header .ant-space
  {
    gap: 4px;
  }
  .insight-header
  {
    padding: 10px 8px;
  }
} 
@media screen and (max-width:1348px)
{
  .insight-header .ant-space
  {
    gap: 3px;
  }
 
  .common-btn
  {
    padding: 0 4px;
  }
  .menu-dropdown
  {
    padding: 11px 5px;
  }
  .feedback-link
  {
    gap: 4px !important;
    font-size: 14px;
  }
  .label-dropdown {
    padding: 0 5px; 
  }
  .arrow-btn {
      padding: 0 2px;
  }
} 

.dashboard-menu {
  border-right: none !important;
}

.dashboard-menu .ant-menu-item {
  height: 48px;
  line-height: 48px;
  margin: 4px 0;
  border-radius: 4px;
}

.dashboard-menu .ant-menu-item:hover {
  background-color: #E9E9F5;
}

.dashboard-menu .ant-menu-item-selected {
  background-color: #E9E9F5 !important;
  color: #32377F !important;
  font-weight: 500;
}

.dashboard-sidebar {
  height: 100%;
  background-color: #fff;
}

.dashboard-sidebar-container {
  background-color: #fff;
  border-right: 1px solid #ccc;
}

.dashboard-content-container {
  background-color: #fff;
  padding: 20px;
}

.dashboard-menu .ant-menu-item {
  margin: 4px 0 !important;
  width: calc(100% - 16px) !important;
  border-radius: 4px !important;
}

.dashboard-menu .ant-menu-item-selected {
  background-color: #E9E9F5 !important;
  color: #32377F !important;
  font-weight: 500 !important;
}

.dashboard-menu .ant-menu-item:hover {
  color: #32377F !important;
}

.rotate-icon{
  transform: rotate(180deg);
}

.space-y-2 > * + * {
  margin-top: 0.5rem;
}

.list-decimal {
  list-style-type: decimal;
}

.custom-node[data-type="result"] {
  background-color: #252963;
  color: white;
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.custom-node[data-type="result"]:hover {
  opacity: 0.9;
}

.result-node {
  background-color: #FFF9C4 !important; /* Light yellow color */
  border: 1px solid #FFF176;
  color: #000000;
  padding: 8px 16px;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.result-node:hover {
  background-color: #FFF59D !important;
}

.result-node .node-content {
  display: flex;
  align-items: center;
  gap: 8px;
}

.result-node .node-label {
  font-weight: 500;
}




.divide-box > div.ml-6 {
  margin: 0;
  padding: 6px 0;
  border-bottom: 1px solid #ededed;
}

.folder-name .icons{
  display: none;
}
.folder-name:hover .icons{
  display: flex;
}
:where(.css-dev-only-do-not-override-apn68).ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: #252963;
  background-color: transparent;
}
:where(.css-dev-only-do-not-override-apn68).ant-radio-wrapper .ant-radio-inner::after
{
  background-color: #252963;
  width: 18px;
  height: 18px;
  margin-block-start: -9px;
  margin-inline-start: -9px;
}
.ant-radio-wrapper:hover :where(.css-dev-only-do-not-override-apn68).ant-radio-wrapper, :where(.css-dev-only-do-not-override-apn68).ant-radio-wrapper:hover .ant-radio-inner {
  border-color: #252963;
}
.select-file.active:hover span{
  color: #fff !important;
}
  
.select-file.active span{
  color: #fff !important;
}
  
.three-column
{
  -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
}

table{
  scrollbar-width: thin;
  scrollbar-color: #eaeaea transparent;
  scrollbar-gutter: stable;
}

.ant-table-body{
  overflow: auto !important;
}

.task-sidebar{
  transition: all 0.5s ease-in-out 0s;
  height: calc(100vh - 54px)
}

.task-page{
  transition: all 0.5s ease-in-out 0s;
}
/* .correlation-heatmap [type="heatmap"] , .correlation-heatmap  .apexcharts-canvas , .correlation-heatmap  .apexcharts-canvas svg
{
  width: 100% !important;
} */

.stepsheight .ant-card-head
{
  background: #e5e7eb;
}

.disable-builder .ruleGroup {
  background: #ddd9d9c9;
  border-color: #cfcfcfbf;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3; /* Ensure it is above other content */
}

.overlay-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.gray-image {
  filter: grayscale(100%) opacity(80%);
  height: 18px
}

.compare-row{
  background-color: #FDEB90 !important;
}

.compare-row:hover td {
  background-color: #FDEB90 !important;
}